/* Variables */

//brand colors
$brand: #7dcf82;
$brand80: #a6d7a1;
$brand50: #c7e6c4;
$brand-secondary: #fe9000;
$brand-secondary50: #f7ca9b;
$brand-orange: $brand-secondary;
$brand-tertiary: #23a6d7;
$brand-tertiary50: #a7d1e9;
$brand-blue: $brand-tertiary;
$brand-tan: lighten(#f8f4e8, 3%);
$tan-dark: #e5e2d6;

$interactive: #8ec788;

//grays
$dark: #49525d;
$ultra-light: #f5f5f5;
$light: #dbdcdf;
$medium: #a4a8ae;
$medium-light: lighten($medium, 10%);
$medium-dark: darken($medium, 10%);
$tan: $brand-tan;

//utility colors
$error: #d2441e;

//add key variables to the DOM for JS access
:root {
  --brand: #{$brand};
  --brand-secondary: #{$brand-secondary};
  --dark: #{$dark};
  --light: #{$light};
  --medium: #{$medium};
}

//measures
$x: 70px;
$x-sm: 30px;
$y: 70px;
$y-sm: 30px;

//sizes
$nav-height: 90px;
$nav-height-md: 90px;
$nav-height-mobile: 76px;
$nav-height-after: 90px;
$container-fluid-max-width: 1280px;
$container-fluid-padding: 100px;
$container-fluid-padding-md: 70px;
$container-fluid-padding-mobile: 20px;
$container-fluid-sum: $container-fluid-padding * 2;
$container-fluid-sum-mobile: $container-fluid-padding-mobile * 2;
$padded-top: 100px;
$padded-bottom: 100px;
$padded-top-sm: 50px;
$padded-bottom-sm: 50px;

//transitions
$modal-transition: 0.4s;

//breakpoints
$xs: 575px;
$sm: 767px;
$md: 991px;
$lg: 1199px;
$xl: 1399px;

//site
$body-background-color: white;

//fonts
$font-light: "FuturaPT";
$font-light-italic: "FuturaPT";
$font-main: "FuturaPT";
$font-italic: "FuturaPT";
$font-medium: "FuturaPT";
$font-medium-italic: "FuturaPT";
$font-bold: "FuturaPT";
$font-bold-italic: "FuturaPT";
$font-secondary: "baloo";
$font-secondary-italic: "baloo";
$font-secondary-medium: "baloo";
$font-secondary-medium-italic: "baloo";
$font-secondary-bold: "baloo";
$font-secondary-bold-italic: "baloo";
