.font-inter {
  font-family: Inter, sans-serif !important;
  font-size: 20px;
  font-weight: 600;

  .cm_headerlinks .navbar-nav {
    font-weight: 500;
  }

  // h4 {
  // 	font-family: baloo, sans-serif;
  // 	font-size: 36px;
  // 	font-weight: normal;
  // 	color: #47525e;
  // }

  h5 {
    font-family: Inter, sans-serif;
    font-size: 30px;
  }

  .h5 {
    font-size: 19px;
  }

  h6 {
    font-size: 17px;
  }

  .two_col_content h3 {
    font-family: Inter, sans-serif;
  }

  .banner-bg h1 {
    font-family: Inter, sans-serif;
  }

  .orders-header-heading {
    font-family: Inter, sans-serif !important;
  }

  .main_content .table {
    th {
      font-weight: 700;
    }
    td {
      font-weight: 500;
    }
  }
}

.underscore {
  &:after {
    margin-top: 0.3em;
    content: "";
    display: block;
    width: 100px;
    height: 5px;
    border-radius: 10px;
    background: $brand;
  }
}

.helper {
  font-weight: 300;
  line-height: 1.2;
  //font-style: italic;
  color: $brand-secondary;
}

.label {
  font-size: 14px;
  @extend .uppercase;
  @extend .tracked-less;
  @extend .bold;
  color: $medium-dark;
  @extend .font-main;

  @media screen and (max-width: $sm) {
    font-size: 12px;
  }
}

.outlined {
  border: 1px solid $tan-dark;
  border-radius: 6px;
}

.boxed {
  @extend .outlined;
  @extend .shadowed;
  padding: 20px;
  background: white;

  @media screen and (max-width: $sm) {
    padding: 10px;
  }
}

.shadowed {
  box-shadow: 0px 0px 3px $tan-dark;
}

.text-shadowed {
  text-shadow: 0px 0px 0.7em black;
}

.hp-alert {
  font-size: 17px;
  line-height: 1.1;

  &.hp-alert-large {
    font-size: 20px;

    * {
      font-size: 20px;
    }
  }

  * {
    font-size: 17px;
    line-height: 1.1;
  }
}
