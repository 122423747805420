:root {
  --color-grey: #9fa6b9;
  --color-light-grey: #dee2e6;
  --font-size-sub: 12px;
  --font-size-base: 16px;
}

@font-face {
  font-family: FuturaPT;
  font-weight: 900;
  src: url(./assets/font/FuturaPTLight.otf);
}
@font-face {
  font-family: FuturaPT;
  font-weight: 400;
  src: url(./assets/font/FuturaPTMedium.otf);
}
@font-face {
  font-family: FuturaPT;
  font-weight: 700;
  src: url(./assets/font/FuturaPTBold.otf);
}
@font-face {
  font-family: FuturaPT;
  font-weight: 600;
  src: url(./assets/font/FuturaPTHeavy.otf);
}
@font-face {
  font-family: FuturaPT;
  font-weight: 500;
  src: url(./assets/font/FuturaPTDemi.otf);
}
@font-face {
  font-family: FuturaPT;
  font-weight: 800;
  src: url(./assets/font/FuturaPTExtraBold.otf);
}
@font-face {
  font-family: baloo;
  src: url(./assets/font/Baloo-Regular.ttf);
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: FuturaPT, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-base);
  color: #1e2538;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:hover {
  text-decoration: none;
}

/* =============== Typography =============== */
sup,
sub {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
}

h1 {
  font-family: baloo, sans-serif;
  font-size: 78px;
  font-weight: normal;
  color: #47525e;
}
h2 {
  font-family: baloo, sans-serif;
  font-size: 48px;
  font-weight: normal;
  color: #47525e;
}
h3 {
  font-family: baloo, sans-serif;
  font-size: 40px;
  font-weight: normal;
  color: #47525e;
}
h4 {
  font-family: baloo, sans-serif;
  font-size: 36px;
  font-weight: normal;
  color: #47525e;
}
h5 {
  font-family: FuturaPT, sans-serif;
  font-size: 36px;
  color: #47525e;
}
h6 {
  font-size: 24px;
  color: #47525e;
  font-weight: 500;
}
a {
  color: #23a6d7;
  text-decoration: none;
}

/* Horizontal Rules */
hr {
  color: #ccc;
  background-color: #ccc;
  height: 1px;
  border: none;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

/* login start */
.login_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: calc(100vh - 360px);
}
.login_wrapper > .row {
  width: 100%;
}
.logininner {
  width: 100%;
}
.login_wrapper h6 {
  font-size: 28px;
  font-family: baloo;
  margin: 20px 0;
}

.form-control {
  font-size: 20px;
  background: #ecedee;
  border-color: #ecedee;
  padding: 10px 20px;
  height: auto;
}

.login_wrapper .btn {
  width: 100%;
  font-size: 20px;
  background: #47525e;
  border-color: #47525e;
  padding: 10px 20px;
}
.login_wrapper .forgot_wrapper {
  font-size: var(--font-size-base);
  color: #47525e;
  display: flex;
  justify-content: space-between;
}

.login_wrapper .forgot_wrapper small {
  font-size: 100%;
}

.body_container_wrapper.login_page {
  height: 100vh;
}

html,
body {
  height: 100%;
}

.footerloginbg {
  background-image: url("./assets/img/loginbg.png");
  background-repeat: no-repeat;
  background-position: center bottom -30px;
  background-size: cover;
  min-height: 250px;
}

/* login end */

.body_container {
  background: #f5f6fa;
  min-height: 100vh;
  padding-left: 340px;
  padding-top: 100px;
  padding-right: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar {
  position: fixed;
  width: 300px;
  background: #fff;
  min-height: 450px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s ease;
  padding: 80px 0;
  text-align: center;
  overflow-y: auto;
}

.header_wrapper {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 999;
  box-shadow: 1px 1px 35px 0px #bbb;
  left: 0;
  padding: 10px 20px 5px;
  min-height: 65px;
}

.header_search {
  position: relative;
  margin-left: 240px;
  width: 100%;
  max-width: 610px;
}

.header_logo img {
  max-width: 80px;
}

.header_wrapper > div.bell_icon {
  float: right;
  margin-top: 9px;
}
.header_wrapper > div {
  float: left;
}

.header_search {
  position: relative;
  margin-left: 240px;
  width: 100%;
  max-width: 610px;
}

.header_search .form-group.has-search {
  margin: 0;
}
.header_search .form-control-feedback {
  position: absolute;
  top: 14px;
  left: 7px;
  cursor: pointer;
  color: #a0a5b9;
  font-size: 21px;
}

/* ordet td*/
span.inlinetext-align {
  position: relative;
  display: inline-block;
}

span.cm-aligntext {
  display: inline-block;
  padding-left: 20px;
}
.marGinOrder {
  margin-left: 60%;
}
.marGinOrderTwo {
  margin-left: 30%;
}
span.inabs {
  position: absolute;
  left: 0;
  top: 0;
}

/**/
.header_search input.form-control {
  padding-left: 40px;
  width: 100%;
  background: transparent;
  border: 0;
  box-shadow: none;
}
.sidebar .logo {
  padding: 40px 10px;
}

.sidebar .logo p {
  font-size: 18px;
  color: #1e2538;
  margin: 20px 0 0;
}

.sidebar_menu {
  text-align: left;
  padding: 0 10px;
}

.sidebar_menu a {
  color: var(--color-grey);
  font-size: var(--font-size-base);
}

.sidebar_menu .nav-item {
  padding: 20px 0;
  max-width: 80%;
  margin: 0 auto;
}

.sidebar_menu .nav-item a.active {
  color: #1e2538;
}
.sidebar_menu .nav-item img {
  margin-right: 20px;
  filter: grayscale(1);
}

.sidebar-item-icon {
  display: inline-block;
  max-width: 40px;
  width: 100%;
}
.sidebar_menu .nav-item a.active img {
  filter: grayscale(0);
}
.sidebar_menu .nav-item a.active img {
  filter: grayscale(0);
}

.sidebar_button {
  margin-top: 30px;
}

.btn-info {
  background: #23a6d7;
  border-color: #23a6d7;
}

.btn {
  font-size: 18px;
  padding: 9px 10px;
  width: 100%;
  max-width: 160px;
}

/* order start */

.body_inner_wrapper {
  background: #fff;
  border-radius: 10px;
  position: relative;
}

header.header_content {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.main_content {
  padding: 20px;
  border-top: 1px solid #dee2e6;
}

.main_content .table thead th {
  border-top: 0;
  color: var(--color-grey);
  font-size: var(--font-size-sub);
  font-weight: normal;
  white-space: nowrap;
}

.main_content .table td {
  color: #1e2538;
  font-size: var(--font-size-sub);
}

.main_content .table td.harvest-items-table-cell {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.main_content .table td > span.badge {
  padding: 10px;
  display: block;
  width: 100%;
}

header.header_content p {
  color: #1e2538;
  font-size: var(--font-size-base);
}
header.header_content button.btn {
  font-size: var(--font-size-sub);
  max-width: unset;
  box-shadow: none;
}

header.header_content button.btn i {
  margin-right: 5px;
}

.action_wrapper > span:nth-child(even) {
  padding: 0 8px;
}

.action_wrapper > span .fa-eye {
  color: #f78f1e;
  font-size: 15px;
}
/* .main_content .table tr {
  cursor: pointer;
} */
.cm_order_view .modal-dialog {
  /* height: calc(100% - 3.5rem); */
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  border-radius: 20px;
}
.order-detailsmodal {
  color: var(--color-grey);
}
.print_button.orderabs {
  position: absolute;
  right: 40px;
  top: 20px;
}
.print_button.orderabsInvoice {
  position: absolute;
  right: 40px;
  top: 4px;
}
.order-detailsmodal p span {
  color: #000;
  margin: 0 0 0 3px;
}
.order-detailsmodal .modal-dialog {
  max-width: 800px;
}
.order-detailsmodal button.close span {
  color: #fff;
  opacity: 1;
}

.order-detailsmodal button.close {
  opacity: 1;
}
.order-detailsmodal table.table {
  color: var(--color-grey);
}
.order-detailsmodal span {
  color: #000;
}
.cm_order_view * {
}

.cm_order_view .modal-header {
  justify-content: center;
  padding-top: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.cm_order_view .modal-header .modal-title.h4 {
  font-size: var(--font-size-base);
}

.cm_order_view button.close {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 20px;
  background: #000;
  padding: 0 6px;
  margin: 0;
  color: #fff;
  opacity: 1;
  font-weight: normal;
}

.cm_order_view .modal-header .modal-title.h4 p {
  margin: 0;
}

.cm_order_view .modal-body {
  padding-left: 50px;
  padding-right: 50px;
}

.cm_order_view .modal-body ul {
  padding: 0;
  list-style: none;
  margin: 0;
  color: var(--color-grey);
}

.cm_order_view .modal-body .row {
  padding: 20px 0;
}

.cm_order_view .modal-body ul li span {
  color: #1e2538;
}

.cm_order_view .modal-body ul li {
  margin: 0 0 10px;
}

.print_button {
  text-align: center;
  border-radius: 10px;
}

.print_button .btn-light .fa-print {
  color: #fc4b4b;
  margin-right: 10px;
}

.order_items {
  color: var(--color-grey);
  font-size: var(--font-size-base);
  margin: 0 -5px;
}
.order_items table th {
  font-weight: normal;
  padding: 5px;
}

.cm_order_view .modal-footer {
  display: block;
  padding: 0 49px;
  border-top: 0;
}

.footer_btn_wrapper {
  display: flex;
  justify-content: space-between;
}

.usps_box_border {
  border: 1px solid var(--color-grey);
  padding: 15px;
  margin-bottom: 15px;
}

/* .usps_box_border:last-child {
  border-bottom: 0;
} */

.usps_flex_start {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usps_flex_end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.usps_form_group {
  margin-bottom: 0;
}

.usps_form_group p {
  margin-bottom: 0;
}

.usps_form_group .form-control {
  margin-bottom: 0;
}

.usps_form_row {
  margin-bottom: 10px;
}

.usps_border_bottom {
  border-bottom: 2px solid #f2f1f6;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.usps_border_top {
  border-top: 2px solid #f2f1f6;
  padding-top: 15px;
  margin-top: 15px;
}

.usps_form_label {
  font-weight: bold;
  font-size: 20px;
}

.usps_border_y {
  border-top: 2px solid #f2f1f6;
  border-bottom: 2px solid #f2f1f6;
  padding: 10px 0;
  margin: 20px 0;
}

.usps_border_y p {
  margin-bottom: 0;
}

.usps_total {
  font-weight: bold;
}

.usps_order_button {
  width: auto;
  max-width: none;
}

#usps-shipping-labels {
  height: 675px;
}

.error_msg {
  color: red;
}

@media (max-width: 992px) {
  .error_msg {
    color: red;
    font-size: 14px;
  }
}

.zip-code-input {
  max-width: 200px;
}

.zip-code-container {
  display: flex;
  flex: 0 0 31%;
  margin-right: 0;
  padding-right: 0;
  justify-content: space-between;
  align-items: center;
}

.autocomplete-input-group {
  flex-wrap: nowrap;
}

.autocomplete-divider {
  margin: 10px 0;
}

.cm_order_view .modal-footer .footer_total {
  display: flex;
  justify-content: space-between;
}

.order_items table {
  width: 100%;
}

.order_items table td {
  padding: 5px;
}
.cm_order_view .modal-content {
  padding-bottom: 40px;
}
.cm_order_view.inventry .modal-content {
  height: 100%;
}
/* order end */

/* farm start  */

.file_uploader {
  padding: 20px;
  background: #ecedee;
  position: relative;
  max-width: 160px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 170px;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.file_uploader .custom.custom-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  display: flex;
  height: 100%;
}

.farm_page {
}

.farm_page .row {
  padding-bottom: 25px;
}

.file_uploader .fa-plus-circle {
  font-size: 50px;
  color: var(--color-grey);
}

.file_uploader .custom-file-input {
  height: 100%;
  cursor: pointer;
}

.file_uploader label.custom-file-label {
  height: 100%;
  cursor: pointer;
}
.fileupload_wraper {
  display: flex;
  /* justify-content: space-between; */
  margin: 0 -10px;
}

.fileupload_wraper .file_uploader {
  margin: 0 7px;
  width: 100%;
  background-size: contain;
}

.video_uploader .file_uploader {
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.video_uploader .file_uploader p {
  text-align: center;
  margin: 20px 0 0;
}
.btn-warning {
  background: #f78f1e;
  border-color: #f78f1e;
  color: #fff;
}
/* farm end  */

.distribution_form .form-group > div {
  float: left;
}

.distribution_form .form-group:after {
  content: "";
  clear: both;
  display: block;
}
.distribution_form > .row {
  margin-bottom: 30px;
}
.distribution_form .form-group {
  margin: 0;
}
.distribution_form p span {
  color: #a0a5b9;
}
.distribution_form > .row p {
  margin: 15px 0 0;
}

.delivery-postal-codes {
  margin-bottom: 20px;
}

img.map {
  width: 100%;
}
.header_btn i {
  font-size: 17px;
}

.header_btn .btn-light {
  background: #fff;
  border: 0;
  margin: 0 20px;
  width: fit-content;
}
header.header_content button:last-of-type {
  width: 100%;
}

.header_btn {
  width: auto;
  justify-content: flex-end;
}
.cm-inventory * {
}

.cm-inventory nav.nav.nav-tabs {
  margin-bottom: 30px;
}

.cm-inventory .nav-tabs .nav-link.active {
  background: transparent;
  border: 0;
  color: #23a6d7;
}

.cm-inventory .nav-tabs .nav-link {
  color: var(--color-grey);
  padding: 10px 40px;
  position: relative;
  border: 0;
}

.cm-inventory .nav-tabs .nav-link:after {
  content: "";
  display: block;
  height: 4px;
  width: 100%;
  background: #23a6d7;
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  opacity: 0;
}

.cm-inventory .nav-tabs .nav-link.active:after {
  opacity: 1;
}

.cm-inventory [class*="col"] {
  padding: 0;
}

.cm-inventory .card-title.h5 {
  display: flex;
  justify-content: space-between;
}

.cm-inventory .card-text {
  display: flex;
  justify-content: space-between;
}
.cm-inventory .card-title.h5 p {
  color: var(--color-grey);
  margin: 10px 0 0;
  font-size: 19px;
}
.cm-inventory .card-title.h5 .custom-control label.custom-control-label {
  font-size: 0;
}

.cm-inventory .card-text p {
  color: var(--color-grey);
}
.text-warning {
  color: #f78f1e !important;
}

.text-info {
  color: #23a6d7 !important;
}
.inventry .modal-body .row {
  padding: 5px 0;
}
.dlt_item .modal-body {
  text-align: center;
}

.dlt_item .modal-header {
  border: 0;
}

.dlt_item .modal-footer {
  border: 0;
}

.edit_item .modal-header {
  border: 0;
}

.edit_item .modal-footer {
  border: 0;
}

.u_img {
  position: relative;
  width: 120px;
  margin: 0 auto;
}
.u_img span.img_icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.setting_wrapper * {
}

.setting_wrapper .nav-pills .nav-link.active,
.setting_wrapper .nav-pills .show > .nav-link {
  background: transparent;
  color: #f78f1e;
}

.setting_wrapper .nav-pills .nav-link {
  color: var(--color-grey);
  font-size: 24px;
}

.setting_wrapper .nav-item {
  padding: 10px 0;
  border-bottom: 1px solid #f2f1f6;
}

.setting_wrapper .nav-item:last-child {
  border: 0;
}
.setting_wrapper .verify_id .file_uploader {
  max-width: 100%;
  font-size: 26px;
  color: var(--color-grey);
}
.setting_wrapper .file_uploader {
  max-width: 100%;
  height: 170px;
}
.print_button .btn-light {
  background-color: #ffecec;
  border-color: #ffecec;
  font-size: var(--font-size-base);
  max-width: initial !important;
}
.header_btn > .btn {
  margin: 0 10px;
}
.header_btn > .btn {
  margin: 0 10px;
  padding: 9px 18px;
}

.header_btn .print_button .btn {
  margin: 0;
  padding: 9px 32px;
}

.error {
  color: red;
}
.success {
  color: limegreen;
}

.u_img .file_input input.cm_hide_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.u_img > img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.cm-inventory .card > .inventry_img_wrapper {
  max-height: 220px;
  min-height: 220px;
  position: relative;
}
.cm-inventory .card > .inventry_img_wrapper img.card-img-top {
  max-height: 220px;
  min-height: 220px;
  object-fit: cover;
}

.cm-inventory .edit_dlt {
  position: absolute;
  top: 10px;
  right: 0;
  color: #fff;
  font-size: 19px;
  z-index: 9;
  opacity: 0;
}

.cm-inventory .edit_dlt span {
  margin: 0 8px;
  cursor: pointer;
}

.cm-inventory .card > .inventry_img_wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
}
.cm-inventory .card:hover > .inventry_img_wrapper:after {
  opacity: 0.4;
}

.cm-inventory .card:hover > .inventry_img_wrapper .edit_dlt {
  opacity: 1;
}
.cm_order_view.inventry .modal-dialog {
  height: auto;
}

/*.loader_wrapper {*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  background: #fff;*/
/*  z-index: 9;*/
/*}*/
.loader_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  min-height: calc(100vh - 114px);
  z-index: 9;
}
.loader_wrapper .spinner-border {
  width: 4rem;
  height: 4rem;
  border-width: 6px;
}

.loader_wrapper1 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 12px;
  background: #fff;
  min-height: calc(100vh - 124px);
  z-index: 9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader_wrapperinner {
  text-align: center;
  margin-bottom: 200px;
}
.btn-h-primary {
  background: #23a6d7;
}
.accounttokenback:hover {
  color: #fff;
  background-color: #23a6d7;
  border-color: #23a6d7;
}

.ws-nowrap {
  white-space: nowrap;
}
.accounttokenback {
  display: inline-block;
  margin-top: 30px;
}
.error1 {
  color: red;
  font-size: 30px;
}
span.cm_time_wrapper {
  display: block;
  white-space: nowrap;
  font-size: 13px !important;
}

i span.cm_time_wrapper {
  font-style: italic;
}
.marginT {
  margin-top: 22px !important;

  margin-left: -344%;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
}

.cm-inventory .fa-pen {
  color: #fff;
  position: relative;
}

/* 404 start  */
.cm_errordoc {
  text-align: center;
  width: 100%;
  padding: 70px 0;
}
.cm_error {
  width: 100%;
  text-align: center;
}

.cm_error-code {
  color: #234269;
  font-family: "Fjalla One", sans-serif;
  font-size: 200px;
  line-height: 1;
}
.cm_error-message h2 {
  color: #444;
  display: inline-block;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0 15px;
}
#errorboxbody p {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 25px;
  margin-top: 0;
}
.button-home {
  background: linear-gradient(to bottom, #ff965c 0%, #f87932 100%);
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: 11px;
  font-weight: 400;
  height: 42px;
  letter-spacing: 1px;
  line-height: 42px;
  padding: 0 36px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}
a.button-home:hover {
  text-decoration: none;
  color: #000;
}
/* 404 end */

.rounded-circle {
  height: 149px;
  width: 149px;
  border-radius: 50% !important;
  object-fit: cover;
}
/* demo bugs  */
.max-w-100 {
  max-width: 100%;
}
.forgot_wrapper.form-group a {
  color: #47525e;
}
.cm_checkbox input {
  position: absolute;
  width: 20px;
  height: 20px;
  z-index: 9;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.cm_checkbox span {
  width: 20px;
  height: 20px;
  display: block;
  border: 1px solid #000;
  background: #fff;
  margin-right: 10px;
  cursor: pointer;
}
label.cm_checkbox {
  margin: 0;
  position: relative;
}
label {
  margin-bottom: 0;
  vertical-align: middle;
}

.cm_checkbox input + span:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  position: absolute;
  z-index: 999;
  opacity: 0;
}

.cm_checkbox input:checked + span:after {
  opacity: 1;
}

span.placeholder {
  color: #8e8e8e;
  margin: 10px 0;
  display: inline-block;
  font-size: 20px;
}

.file_uploader > div {
  text-align: center;
}
.errordoc {
  color: red;
}
.btn-warning:focus {
  background: #f78f1e;
  border-color: #f78f1e;
  box-shadow: none;
  color: #fff;
}
a,
button {
  outline: none !important;
}
.dlt_item .modal-body p {
  font-size: 24px;
}
.btn-warning {
  color: #fff !important;
}
.dlt_item .modal-dialog .footer_btn_wrapper {
  justify-content: space-around;
}
.dlt_item .modal-dialog {
  max-width: 510px;
}

.dlt_item__usps .modal-dialog {
  max-width: 750px;
}

.custom-control-label {
  cursor: pointer;
}
input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}
.react-thumbnail-generator img {
  max-width: 140px;
}

.font_12 {
  font-size: 11px;
}
.c-pointer {
  cursor: pointer;
}

.cm_placeorder_wrapper .card-header button {
  max-width: 100%;
}
.cm_placeorder_wrapper .card-header .cm_time_wrapper {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 13px;
}
.accordian_wrapper {
  display: flex;
}

.accordian_wrapper select.form-control {
  background: transparent;
  border: 1px solid #000;
  box-shadow: none;
}
.cm-inventory .inventrycard_heading {
  color: #000 !important;
  display: flex;
  justify-content: space-between;
}

.cm-inventory .card-body {
  padding: 15px;
}

.cm_edit_item {
  position: relative;
}
.accordian_wrapper .form-control {
  font-size: var(--font-size-sub);
}
.cm_edit_item i.fas.fa-pen.font_12 {
  position: absolute;
  top: 20px;
  right: 10px;
}

.cm_time_wrapper span {
  text-decoration: none;
}

.cm_placeorder_wrapper button.btn.btn-link {
  text-decoration: none;
  padding-left: 0;
  padding-right: 0;
}
.cm_placeorder_wrapper .accordion > .card > .card-header {
  background: #fff;
  padding-left: 0;
  padding-right: 0;
}

.cm_placeorder_wrapper .accordion > .card > .card-header:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
.cm_order_view .card {
  border-left: 0;
  border-right: 0;
}
input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}
.fade.modal-backdrop {
  z-index: 999;
}
.page_heading {
  border-top: 1px solid #dee2e6;
  padding: 20px;
}
header.header_content p {
  margin: 0;
}
.page_heading h6 {
  margin: 0;
  font-size: 18px;
}
.cm_order_view.cm_placeorder_wrapper {
  z-index: 999;
}
.success_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffae68;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success_inner_wrapper {
  background: #fff;
  border-radius: 10px;
  width: 100%;
}
.sub_heading {
  line-height: 0.7;
  padding-left: 10px;
  font-weight: 900;
  font-size: 15px;
}
.sub_heading .fa-caret-right {
  margin-right: 10px;
  font-size: 11px;
}
.cm-accordian {
  margin-bottom: 20px;
}
.distributionedit-page {
  padding: 0 0 0 40px;
}
.font-light {
  font-weight: 900;
}
.c-pnt {
  cursor: pointer;
}
.time_itemwrapper .accordian_wrapper {
  margin-bottom: 20px;
}
.edit-distribution .accordion > .card:first-child {
  border-top: 0;
}
.cm-modal-height .modal-dialog {
  height: auto;
}
.distribution-modal .main_content {
  border: 0;
  padding: 0;
}

.distribution-modal .modal-body {
  padding-left: 30px;
  padding-right: 30px;
}

.distribution-modal .distributionedit-page {
  padding: 0;
}

.distribution-modal .sub_heading > .row {
  padding: 0;
}
.distribution-modal .modal-body p {
  margin-bottom: 10px;
}

/* ---------------------pagination------------------- */
ul.pagination li a,
ul.pagination li.disabled a,
ul.pagination li.disabled a:hover {
  color: #0a0a0a;
  background: #ffffff;
  border: 1px solid #ccc;
}
ul.pagination li:first-child,
ul.pagination li:last-child {
  display: none;
}

ul.pagination li {
  text-align: center;
}
ul.pagination li:hover a,
ul.pagination li.active a {
  background: #139bf1;
  color: #fff;
}
ul.pagination li a {
  border-radius: 2px;
}
ul.pagination li {
  margin-right: 10px;
  background: white;
}
ul.pagination li.nextClass a {
  background: #fff;
  border-color: #139bf1;
  color: #139bf1;
}

ul.pagination li.prevClass a {
  background: #fff;
  border-color: #139bf1;
  color: #139bf1;
}

ul.pagination li.prevClass.disabled a,
ul.pagination li.nextClass.disabled a {
  color: #0a0a0a !important;
  background: #ffffff !important;
  border: 1px solid #ccc !important;
  cursor: not-allowed;
}
button#categories {
  padding: 0;
  background: transparent;
  color: var(--color-grey);
  font-size: var(--font-size-sub);
  font-weight: normal;
  border: 0;
  outline: none;
  box-shadow: none;
}
.font_14 {
  font-size: 14px;
}
.s_no {
  width: 60px;
}
.cm-pagination ul.pagination li a {
  padding: 0 6px 1px;
}
.network-error {
  font-size: 14px;
  text-align: center;
  display: block;
  color: red;
}
.edit_dlt i {
  color: #fff;
  position: relative;
}
.mini_loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  min-height: 100%;
  z-index: 9;
  border: 1px solid #eee;
}
.fa-pen {
  position: relative;
}
.cm_edit_item .fa-plus-circle {
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 15px;
}
.bg_size {
  background-size: cover !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* .overflow_remove {
  overflow-x: visible;
} */
.video_upload {
  height: 170px;
}
.videothumb {
  padding: 0;
}
input[type="radio"] {
  vertical-align: inherit;
  margin: 0 5px;
}
.videothumb img {
  height: 170px;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.videothumb .react-thumbnail-generator {
  width: 100%;
}
.inventry label {
  vertical-align: inherit;
}

.view_sec select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.pricetag {
  min-width: 22px;
  display: inline-block;
}
.setting_wrapper .file_uploader .document-container {
  width: 100%;
}

.setting_wrapper .file_uploader .pg-viewer-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.upload-onicon input.custom-file-input {
  z-index: 9;
}
.upload-onicon .custom.custom-file {
  z-index: 9;
}

.cm_phnnumber input.form-control {
  width: 100%;
  font-size: 17px;
  background: #ecedee;
  border-color: #ecedee;
  padding: 10px 20px;
  height: auto;
  padding-left: 53px;
}
.cm_phnnumber .react-tel-input .flag-dropdown {
  border: 0;
  background-color: #ecedee !important;
  border-right: 1px solid #cacaca;
  padding-right: 3px;
}
.cm_phnnumber .react-tel-input .flag-dropdown.open {
  background: #ecedee !important;
}

.cm_phnnumber .react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}
.cm_phnnumber .react-tel-input .flag-dropdown:hover .selected-flag,
.cm_phnnumber .react-tel-input .flag-dropdown:focus .selected-flag {
  background: transparent;
}
.imgas_bg {
  position: absolute;
  width: 100%;
  height: 90%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.item_iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.item_iframe iframe {
  height: 100%;
  width: 100%;
}
.unitvalue {
  min-height: 24px;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.cardwidth {
  width: 100%;
}
.itemwidth_inventry .card {
  min-height: 500px;
}
.itemwidth_inventry {
  max-width: 33%;
  flex: 0 0 33%;
}
.ws-nowrap {
  white-space: nowrap;
}
span.cm_time_wrapper {
  display: inline-block;
}
.cmeditdistribution .form-control {
  padding-left: 10px;
}
.action_wrapper1 i {
  cursor: pointer;
}

.action_wrapper1 > span:nth-child(even) {
  padding: 0 15px;
}

.action_wrapper1 > span .fa-eye {
  color: #f78f1e;
  font-size: 15px;
}
.tabletoggletext {
  padding: 0;
  font-size: var(--font-size-sub);
}
.action_wrapper1 button {
  font-size: var(--font-size-sub);
  padding: 5px 6px;
  max-width: none;
  width: auto;
  margin-left: 10px;
}
.action_wrapper1 {
  white-space: nowrap;
}
span.inlinetext-align {
  position: relative;
  display: inline-block;
}

span.cm-aligntext {
  display: inline-block;
  padding-left: 20px;
}

span.inabs {
  position: absolute;
  left: 0;
  top: 0;
}
/* .printpdf .modal-dialog {
  width: 800px;
  overflow: auto;
  display: block;
  max-width: initial;
} */
/* .printpdf .modal-content {
  width: 800px;
  height: 1116px;
  margin: 0 auto;
  overflow: auto;
}
.pdf-responsive {
  width: 790px;
  position: relative;
  height: 1030px;
} */
/*.pdfwithheight {*/
/*  width: 690px;*/
/*  height: 834px;*/
/*}*/

.action_wrapper span {
  cursor: pointer;
}
.pdf-responsive .modal-body {
  overflow: auto;
}
.printpdf .modal-content {
  padding: 0;
}
.invoicewrapper {
  /* width: 830px; */
  margin: 0 auto;
  overflow: auto;
}
.print_button.orderabs.noabs {
  position: static;
}
.tablecenter {
  margin: 0 auto;
}
.pdfwrapper {
  width: 1245px;
  margin: 0 auto;
}
.cm-mr {
  margin-right: 300px;
}
.cm-ml {
  margin-left: 270px;
}

.table.no-border td,
.table.no-border th {
  border: 0;
}
div#jsx-template {
  overflow: auto;
  display: none;
}
div#divIdToPrint header.header_content {
  display: flex;
}
.printleft {
  margin-right: 180px;
}
div#divIdToPrint {
  width: 1070px;
}

.pdfbtn {
  padding: 30px;
  text-align: center;
}
.pdfbtn .btn {
  margin: 10px 10px;
}

.login_page .container > h1 {
  font-size: initial;
  padding-top: 10px;
}
.fileupload_wraper .file_uploader {
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
  line-height: 130px;
}
.fileupload_wraper {
  display: block;
}
.cmbtngrp .btn {
  margin: 10px;
}
.cm_edit_item.adddaytime .form-control {
  padding-right: 30px;
}
.action_wrapper {
  white-space: nowrap;
}
.mobilebtn-setting.cmbtngrp {
  text-align: right;
}
.setting_wrapper [class*="col"] .row {
  align-items: center;
}

.setting_wrapper .row p {
  margin: 0;
}
.addurbilltext a {
  display: inline-block;
}

.addurbilltext {
  padding: 0 20px;
}
.printlogo.header {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.mobilebtn-setting .btn {
  max-width: initial;
  width: auto;
}
.dlticon {
  margin: 0 10px;
}
.dwnload-icon a {
  color: #000;
}

.upload-onicon {
  background: #fff;
  border-radius: 50%;
  padding: 10px 13px;
  color: #000;
}
.dwnload-icon {
  color: #000;
  position: absolute;
  right: 20px;
  top: 0;
  padding: 10px 14px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
}
.upload-onicon {
  position: absolute;
  padding: 10px 13px;
  top: 0;
  right: 70px;
}
.productmodal .modal-header {
  border: 0;
}

.product-wrapper {
  padding: 30px;
}

.product-item {
  margin: 0 0 40px;
}

.productmodal .cm_productimg {
  background: #eee;
  padding: 20px;
  text-align: center;
}

.productmodal .cm_productimg img {
  width: 100%;
  object-fit: contain;
  max-width: 80%;
}

@media (max-height: 700px) {
  .login_wrapper {
    height: calc(100vh - 240px);
  }
  /* .footerloginbg {
    background-size: contain;
  } */
  .login_wrapper img {
    max-width: 70px;
  }

  .login_wrapper .form-control,
  .login_wrapper button.btn {
    margin-bottom: 10px;
    padding: 6px 20px;
    font-size: 15px;
  }
  .login_page .navbar-brand img {
    max-width: 50px;
  }
  .footerloginbg {
    min-height: 176px;
  }
}

@media (min-width: 1350px) {
  .itemwidth_inventry {
    max-width: 25%;
    flex: 0 0 25%;
  }
}

@media (max-width: 1400px) {
  .header_btn .print_button .btn {
    margin: 0 0 10px;
  }
  .d-flex.text-nowrap.header_btn {
    display: block !important;
    white-space: normal !important;
    padding-left: 20px;
  }
  header.header_content button.btn {
    width: auto;
    margin: 0 10px 10px 0;
  }
  .print_button {
    display: inline;
  }
  .overflow_remove {
    overflow-x: auto;
  }
  .header_search {
    max-width: max-content;
  }
}

/* responisve  */
@media (max-width: 1200px) {
  div#divIdToPrint header.header_content {
    display: flex;
  }

  .header_btn .print_button .btn {
    padding: 9px 12px;
  }
  .header_btn {
    margin-top: 10px;
  }
  .rounded-circle {
    height: 99px;
    width: 99px;
  }

  .sidebar {
    width: 200px;
  }
  .body_container {
    padding-left: 240px;
  }
  .action_wrapper {
    width: 70px;
  }
  .header_wrapper {
    display: flex;
  }
  .main_content .table thead th {
    white-space: nowrap;
  }
  .main_content .table-responsive.overflow_remove {
    min-height: 170px;
  }
  span.cm_time_wrapper {
    font-size: var(--font-size-base);
  }
}
@media (max-width: 1000px) {
  .addurbilltext {
    padding: 10px 0 0;
  }
  .form-control,
  .cm_phnnumber input.form-control {
    padding: 5px 8px;
  }
  .d-flex.text-nowrap.header_btn {
    padding-left: 0;
  }
  header.header_content {
    display: block;
  }

  .itemwidth_inventry {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .setting_wrapper .nav-pills .nav-link {
    color: var(--color-grey);
    font-size: 18px;
  }
  .btn {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .footer_btn_wrapper {
    justify-content: space-around;
  }
  .cm_edit_item .fa-plus-circle {
    top: 12px;
  }
  .edit-distribution.modal label {
    margin: 0;
  }
  .cmbtngrp {
    text-align: center !important;
  }
  .tab-content {
    padding: 10px;
  }
  .header_btn .print_button .btn {
    margin: 0 0 10px;
  }
  .fileupload_wraper .file_uploader {
    max-width: 150px;
    min-height: 110px;
    line-height: 110px;
  }
  label {
    margin-bottom: 10px;
  }
  .adddistribution .distribution_form > .row p {
    margin-bottom: 10px;
  }
  .sidebar_button .btn {
    margin-right: 10px;
  }
  .distribution_form > .row p {
    margin: 0 0 0;
  }
  .time_itemwrapper .accordian_wrapper span.mx-5 {
    margin: 0 6px !important;
  }
  .mb-sm-3 {
    margin-bottom: 20px !important;
  }
  .distribution_form > .row {
    margin-bottom: 0;
  }
  .btn {
    margin-bottom: 10px;
  }
  .form-control {
    overflow: hidden;
  }
  .mobilebtn-setting {
    margin: 20px 0 30px;
  }
  .itemwidth_inventry {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .sidebar {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 60px;
    min-height: auto;
    bottom: 0;
    top: auto;
    background: #eee;
    justify-content: space-around;
  }

  .sidebar .logo {
    padding: 0;
    display: none;
  }

  .sidebar_menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
  }

  .sidebar_menu .nav-item {
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
  }

  .sidebar_menu .nav-item img {
    display: block;
    margin: 0 auto;
  }

  .sidebar-item-icon {
    display: block;
    text-align: center;
    max-width: 100%;
  }

  .sidebar_button {
    margin: 0;
  }

  .sidebar_button button {
    padding: 3px 7px;
    width: auto;
    font-size: var(--font-size-sub);
  }

  .sidebar_menu a {
    font-size: 13px;
  }

  .body_container {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 120px;
  }
  .cm-inventory .nav-tabs .nav-link {
    padding: 10px 8px;
    font-size: 11px;
  }

  .main_content {
    padding: 10px;
  }
  .cm_order_view .modal-body {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-open .modal {
    padding: 10px !important;
  }

  .footer_btn_wrapper button {
    margin: 0 10px;
  }

  .cm_order_view .modal-footer {
    padding: 0 0;
  }
  .header_search {
    margin: 0 15px;
    max-width: 610px;
  }
  .form-control {
    margin-bottom: 20px;
  }
  .farm_page .row {
    padding: 0 0 10px;
  }
  .header_search .form-control-feedback {
    font-size: 15px;
    top: 17px;
  }

  .header_search input.form-control {
    padding-left: 30px;
    margin: 0;
  }
  .modal-content .button.btn {
    font-size: var(--font-size-base);
    padding: 6px 8px;
    width: 100%;
    max-width: 110px;
  }
  .button.btn {
    font-size: var(--font-size-base);
    padding: 6px 8px;
  }
}

@media (max-width: 567px) {
  .footer_btn_wrapper {
    display: block;
    text-align: center;
  }

  .footer_btn_wrapper .btn {
    margin: 10px;
  }
  .print_button.orderabs {
    right: auto;
    left: 10px;
    top: 10px;
  }
  .order-detailsmodal .modal-header .h4 {
    margin-top: 40px;
  }

  .order-detailsmodal .modal-header .h4 h6 {
    font-size: 20px;
  }
  .cm_order_view .card-body {
    padding-left: 0;
    padding-right: 0;
  }

  .time_itemwrapper .accordian_wrapper select.form-control {
    margin: 0;
  }
  .sidebar_menu .nav-item:nth-child(2) img {
    max-width: 22px;
  }
  .setting_wrapper .verify_id .file_uploader {
    font-size: 19px;
  }
  .fileupload_wraper .file_uploader {
    max-width: 130px;
    min-height: 100px;
    line-height: 60px;
  }
  .itemwidth_inventry {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .sidebar_menu a {
    font-size: 10px;
  }

  .sidebar_menu .nav-item img {
    max-width: var(--font-size-base);
  }

  .sidebar_menu .nav-item {
    padding: 5px 5px;
  }
}

/* utilities */
.font-weight-400 {
  font-weight: 400;
}
.font-weight-700 {
  font-weight: 700;
}

.font-size-sub {
  font-size: var(--font-size-sub);
}

.font-size-base {
  font-size: var(--font-size-base);
}

.color-light-grey {
  color: var(--color-light-grey);
}
.color-grey {
  color: var(--color-grey);
}
.border-bottom-width-2px {
  border-bottom-width: 2px;
}
.border-bottom-style-solid {
  border-bottom-style: solid;
}
.border-bottom-color-grey {
  border-bottom-color: var(--color-grey);
}
.border-bottom-color-light-grey {
  border-bottom-color: var(--color-light-grey);
}
.vertical-align-top {
  vertical-align: top;
}
.text-align-center {
  text-align: center;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-base {
  margin-bottom: var(--font-size-base);
}
.margin-bottom-base-2x {
  margin-bottom: calc(var(--font-size-base) * 2);
}
.padding-left-0,
.table .padding-left-0 {
  padding-left: 0;
}
.padding-right-0,
.table .padding-right-0 {
  padding-right: 0;
}
.padding-left-base {
  padding-left: var(--font-size-base);
}
.padding-right-base {
  padding-right: var(--font-size-base);
}
.padding-top-base {
  padding-top: var(--font-size-base);
}
.padding-top-0,
.table .padding-top-0 {
  padding-top: 0;
}
.padding-bottom-base {
  padding-bottom: var(--font-size-base);
}
.padding-top-half-base {
  padding-top: calc(var(--font-size-base) / 2);
}
.padding-bottom-half-base {
  padding-bottom: calc(var(--font-size-base) / 2);
}
/* harvest list widths */
.hl-th-min-width-standard {
  min-width: 150px;
}
.hl-th-min-width-small {
  min-width: 50px;
}
.hl-table-max-width-large {
  max-width: 400px;
}
.display-table-cell {
  display: table-cell;
}
.visibility-collapse {
  visibility: collapse;
}
.table thead.visibility-collapse th {
  border: none;
}
.table .visibility-collapse + tbody tr:first-child td {
  border: none;
  padding-top: 0;
}
