/* Sizing heights and widths with viewport units*/

@for $i from 1 through 150 {
  .vh#{$i*1} {
    height: 1vh * $i;
    //height: calc(var(--vh, (5vh * #{$i})) * 100);
  }
}

@for $i from 1 through 150 {
  .vw#{$i*1} {
    width: 1% * $i;
  }
}

@for $i from 1 through 150 {
  .vhmin#{$i*1} {
    min-height: 1vh * $i;
    //min-height: calc(var(--vh, (5vh * #{$i})) * 100);
  }
}

@for $i from 1 through 150 {
  .vhmax#{$i*1} {
    max-height: 1vh * $i;
    //max-height: calc(var(--vh, (5vh * #{$i})) * 100);
  }
}

.vhfull {
  min-height: calc(100vh - #{$nav-height});
  min-height: calc((var(--vh, 1vh) * 100) - #{$nav-height});

  @media screen and (max-width: $sm) {
    min-height: calc(100vh - #{$nav-height-mobile});
    min-height: calc((var(--vh, 1vh) * 100) - #{$nav-height-mobile});
  }
}

.height-100 {
  height: 100%;
}
