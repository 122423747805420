/* Margins and Padding */

//margins

.m0 {
  margin: 0;
}

@for $i from 0 through 9 {
  .m#{$i} {
    margin-bottom: 1em * $i;
    margin-top: 1em * $i;
  }
}

@for $i from 0 through 9 {
  .mb#{$i} {
    margin-bottom: 1em * $i;
  }

  .mb#{$i}-xs {
    @media screen and (max-width: $xs) {
      margin-bottom: 1em * $i;
    }
  }
}

@for $i from 0 through 9 {
  .mt#{$i} {
    margin-top: 1em * $i;
  }
}

@for $i from 0 through 9 {
  .ml#{$i} {
    margin-left: 1em * $i;
  }
}

@for $i from 0 through 9 {
  .mr#{$i} {
    margin-right: 1em * $i;
  }
}

//padding

@for $i from 0 through 9 {
  .p#{$i} {
    padding: 1em * $i;
  }
}

@for $i from 0 through 9 {
  .pb#{$i} {
    padding-bottom: 1em * $i;
  }
}

@for $i from 0 through 9 {
  .pt#{$i} {
    padding-top: 1em * $i;
  }
}

@for $i from 0 through 9 {
  .pl#{$i} {
    padding-left: 1em * $i;
  }
}

@for $i from 0 through 9 {
  .pr#{$i} {
    padding-right: 1em * $i;
  }
}

.padded {
  padding-top: $padded-top;
  padding-bottom: $padded-bottom;

  @media screen and (max-width: $sm) {
    padding-top: $padded-top-sm;
    padding-bottom: $padded-bottom-sm;
  }
}

.padded-top {
  padding-top: $padded-top;

  @media screen and (max-width: $sm) {
    padding-top: $padded-top-sm;
  }
}

.padded-bottom {
  padding-bottom: $padded-bottom;

  @media screen and (max-width: $sm) {
    padding-bottom: $padded-bottom-sm;
  }
}

.padded-more {
  padding-top: $padded-top * 1.5;
  padding-bottom: $padded-bottom * 1.5;

  @media screen and (max-width: $sm) {
    padding-top: $padded-top-sm * 1.5;
    padding-bottom: $padded-bottom-sm * 1.5;
  }
}
