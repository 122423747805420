@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?tu7v6k");
  src: url("fonts/icomoon.eot?tu7v6k#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?tu7v6k") format("truetype"),
    url("fonts/icomoon.woff?tu7v6k") format("woff"),
    url("fonts/icomoon.svg?tu7v6k#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sickle:before {
  content: "\e900";
  color: #9fa6b9;
}
