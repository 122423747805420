//basics and font styles

body {
  // font-family: $font-main, sans-serif;
  // color: $dark;
  // font-size: 21px;

  // @media screen and (max-width: $lg) {
  // 	font-size: 20px;
  // }

  // @media screen and (max-width: $md) {
  // 	font-size: 18px;
  // }

  // @media screen and (max-width: $sm) {
  // 	font-size: 16px;
  // }
}

.font-main {
  font-family: $font-main;

  &.italic {
    font-family: $font-italic;
  }
}

.font-main-light {
  font-family: $font-light;

  &.italic {
    font-family: $font-light-italic;
  }
}

.font-main-medium {
  font-family: $font-medium;

  &.italic {
    font-family: $font-medium-italic;
  }
}

.font-main-bold {
  font-family: $font-bold;

  &.italic {
    font-family: $font-bold-italic;
  }
}

.font-secondary {
  font-family: $font-secondary;

  &.italic {
    font-family: $font-secondary-italic;
    font-style: italic;
  }

  &.bold,
  &strong {
    font-family: $font-secondary-bold;
  }
}

.font-secondary-medium {
  font-family: $font-secondary-medium;
}

.font-secondary-bold {
  font-family: $font-secondary-bold;
}

//type sizes

// h1,h2,h3,h4,h5,h6{
// 	font-family: $font-bold;
// 	line-height: 1.3;

// 	span, a, em{
// 		font-family: $font-bold;
// 	}
// }

// h1,.h1{
// 	font-size: 48px;

// 	@media screen and (max-width: $lg) {
// 		font-size: 42px;
// 	}

// 	@media screen and (max-width: $md) {

// 	}

// 	@media screen and (max-width: $sm) {
// 		font-size: 36px;
// 	}

// 	@media screen and (max-width: $xs) {

// 	}
// }

// h2,.h2{
// 	font-size: 36px;

// 	@media screen and (max-width: $lg) {
// 		font-size: 30px;
// 	}

// 	@media screen and (max-width: $md) {

// 	}

// 	@media screen and (max-width: $sm) {
// 		font-size: 24px;
// 	}

// 	@media screen and (max-width: $xs) {

// 	}
// }

// h3,.h3{
// 	font-size: 30px;

// 	@media screen and (max-width: $lg) {
// 		font-size: 24px;
// 	}

// 	@media screen and (max-width: $md) {

// 	}

// 	@media screen and (max-width: $sm) {
// 		font-size: 21px;
// 	}

// 	@media screen and (max-width: $xs) {

// 	}
// }

// .h3-5{
// 	font-size: 24px;

// 	@media screen and (max-width: $lg) {
// 		font-size: 21px;
// 	}

// 	@media screen and (max-width: $md) {
// 		font-size: 20px;
// 	}

// 	@media screen and (max-width: $sm) {
// 		font-size: 19px;
// 	}
// }

// h4,.h4{
// 	font-size: 21px;

// 	@media screen and (max-width: $lg) {
// 		font-size: 20px;
// 	}

// 	@media screen and (max-width: $md) {
// 		font-size: 18px;
// 	}

// 	@media screen and (max-width: $sm) {
// 		font-size: 16px;
// 	}
// }

// h5,.h5{
// 	font-size: 16px;

// 	@media screen and (max-width: $lg) {
// 		font-size: 14px;
// 	}

// 	@media screen and (max-width: $md) {
// 		font-size: 13px;
// 	}

// 	@media screen and (max-width: $sm) {
// 		font-size: 12px;
// 	}
// }

// h6,.h6{
// 	font-size: 12px;

// 	@media screen and (max-width: $lg) {
// 		font-size: 12px;
// 	}

// 	@media screen and (max-width: $md) {
// 		font-size: 10px;
// 	}

// 	@media screen and (max-width: $sm) {
// 		font-size: 10px;
// 	}
// }

// p{
// 	margin-top: 0;
// 	line-height: 1.6 !important;
// }

//formatting

.bold,
strong,
b {
  font-weight: normal;
  font-family: $font-bold;
}

.italic,
em,
i {
  font-style: normal;
  font-family: $font-italic;
}

.uppercase {
  text-transform: uppercase;
}

.underline,
.underlined {
  text-decoration: underline;
}

.centered {
  text-align: center;
}

.righted {
  text-align: right;
}

.tracked-less {
  letter-spacing: 0.05em;
}

.tracked {
  letter-spacing: 0.09em;
}

//typography colors

.brand {
  color: $brand;
}

.brand-secondary {
  color: $brand-secondary;
}

.brand-tertiary {
  color: $brand-tertiary;
}

.orange {
  color: $brand-orange;
}

.blue {
  color: $brand-blue;
}

.white {
  color: white;
}

.ultra-light {
  color: $ultra-light;
}

.light {
  color: $light;
}

.medium-light {
  color: $medium-light;
}

.medium {
  color: $medium;
}

.medium-dark {
  color: $medium-dark;
}

.dark {
  color: $dark;
}

.error {
  color: $error;
}

.tan {
  color: $tan;
}

//icons

.icon {
  font-family: "modern_pictograms_proregular";

  &:before {
    content: attr(data-icon);
    font-family: "modern_pictograms_proregular";
    font-size: 24px;
    line-height: 100%;
    vertical-align: middle;
    position: relative;
  }

  &.large:before {
    font-size: 72px;
  }
}
