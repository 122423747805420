.orders-header {
  display: flex;
  align-items: flex-end !important;
  padding-bottom: 10px !important;

  @media screen and (max-width: $lg) {
    display: block !important;
  }
}

.orders-header-heading {
  font-family: $font-main;
  width: 150px;
  margin-bottom: 0;
  position: relative;
  top: -5px;

  @media screen and (max-width: $lg) {
    width: 100%;
  }

  @media screen and (max-width: $sm) {
    margin-bottom: 10px;
  }
}

.orders-header-buttons {
  display: flex;
  justify-content: flex-end !important;
  flex-wrap: wrap;

  @media screen and (max-width: $lg) {
    justify-content: start !important;
  }

  .btn {
    width: auto !important;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.harvest-list-block {
  margin-bottom: 60px;
  border-bottom: 2px solid $medium;
  padding: 20px 0 0 0;
  border: 1px solid $light;
  border-radius: 4px;

  &:last-child {
    hgroup {
      h6:only-child {
        font-weight: 500;
        letter-spacing: 0;
        text-transform: none;
        font-size: unset;
        @extend .dark;
      }
    }
  }
}

.harvest-list-orders-heading {
  font-family: $font-main;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 20px;
  @extend .uppercase;
  color: $brand-tertiary;
  //margin-bottom: 0;
}

body .main_content .table thead {
  th {
    font-size: 15px;
    color: lighten($dark, 8%);
  }
}

.harvest-list-thead,
.harvest-list-pack-list-thead {
  tr {
    border-top: 2px solid $light;
  }
}

.main_content .table .harvest-list-sub-thead {
  th {
    font-size: 13px;
  }
}

.harvest-list-order-row {
  border-bottom: 1px solid $light;
  .harvest-list-order-customer {
    border-right: 1px solid $light;
  }
}

.harvest-list-orders-sub-table {
  //border-bottom: 1px solid $light;
  padding-bottom: 0 !important;
}

.harvest-list-pack-list {
  margin-bottom: 0;
  tr {
    //border-right: 1px solid $light;
  }
}

hgroup {
  margin-left: 20px;
  //width: 66%;
  //display: flex;

  h6 {
    //display: inline;
    //margin-right: 20px;
  }
  h6:nth-child(2),
  h6:nth-child(3) {
    font-weight: 500;
  }
  h6:first-child {
    font-weight: 500;
    @extend .tracked-less;
    text-transform: uppercase;
    font-size: 14px;
    @extend .medium-dark;
  }
}
